export enum UnsubscribeCategoryType {
    INFORMATIONAL_UPDATES_AND_REMINDERS = "INFORMATIONAL_UPDATES_AND_REMINDERS",
    ESSENTIAL_EMAILS = "ESSENTIAL_EMAILS",
    IMPORTANT_CRITICAL_EMAILS = "IMPORTANT_CRITICAL_EMAILS",
    PRIVATE_MESSAGES = "PRIVATE_MESSAGES",
    UNSUBSCRIBE_ALL = "UNSUBSCRIBE_ALL",
  }
  
  export enum UnsubscribeEmailType {
    APPLICATION_UPDATES = "APPLICATION_UPDATES",
    REMINDERS = "REMINDERS",
    BENEFICIARY_INVITE_ACCEPTANCE = "BENEFICIARY_INVITE_ACCEPTANCE",
    BENEFICIARY_OPT_OUT = "BENEFICIARY_OPT_OUT",
    BENEFICIARY_UNLOCK_REQUEST_CANCELED = "BENEFICIARY_UNLOCK_REQUEST_CANCELED",
    AUTOMATIC_RELOCK = "AUTOMATIC_RELOCK",
    SCHEDULED_BENEFACTOR_UNLINKING = "SCHEDULED_BENEFACTOR_UNLINKING",
    BENEFICIARY_ACCOUNT_UNLOCKED = "BENEFICIARY_ACCOUNT_UNLOCKED",
    BENEFACTOR_ACCOUNT_RELOCKED = "BENEFACTOR_ACCOUNT_RELOCKED",
    BENEFACTOR_ACCOUNT_UNLOCKED = "BENEFACTOR_ACCOUNT_UNLOCKED",
    CONNECTION_REQUEST = "CONNECTION_REQUEST",
    ACCOUNT_UNLOCK_REQUEST = "ACCOUNT_UNLOCK_REQUEST",
    ACCOUNT_UNLOCKED = "ACCOUNT_UNLOCKED",
    BENEFACTOR_ACCOUNT_UNLOCK_REQUEST = "BENEFACTOR_ACCOUNT_UNLOCK_REQUEST",
    PRIVATE_MESSAGES = "PRIVATE_MESSAGES",
    BENEFACTOR_ACCOUNT_UNLOCKED_WITH_BACKUP_FALLBACK = "BENEFACTOR_ACCOUNT_UNLOCKED_WITH_BACKUP_FALLBACK",
    BENEFACTOR_ACCOUNT_UNLOCKED_FOR_BACKUPS = "BENEFACTOR_ACCOUNT_UNLOCKED_FOR_BACKUPS",
  }
  
  export interface UnsubscribeCategory {
    type: UnsubscribeCategoryType;
    name: string;
    description?: string;
    emails?: UnsubscribeEmail[];
  }
  
 export interface UnsubscribeEmail {
    type: UnsubscribeEmailType;
    name: string;
    description: string;
  }

export interface EmailPreferences {
    unsubscribedEmails: UnsubscribeEmailType[];
  }