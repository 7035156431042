import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const handleRedirectToHome = () => {
    navigate("/");
  };

  let showFooter =
    !(["/faq", "/terms", "privacy"].includes(location.pathname) &&
    searchParams.get("hnf") === "");

  return (
    <>
      {showFooter && (
        <footer>
          <p>
            <br />
            <br />
            <button onClick={handleRedirectToHome} className="farewell-notes">
              &#169; FAREWELL NOTES LLC
            </button>{" "}
            {new Date().getFullYear()}
          </p>
        </footer>
      )}
    </>
  );
}

export default Footer;
