import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonMailto from "../components/ButtonMailto";
import {
  UnsubscribeCategory,
  UnsubscribeCategoryType,
  UnsubscribeEmailType,
} from "../models/email";
import { getEmailPreferences, updateEmailPreferences } from "../services/api";
import "./index.css";

const categories: UnsubscribeCategory[] = [
  {
    type: UnsubscribeCategoryType.INFORMATIONAL_UPDATES_AND_REMINDERS,
    name: "Informational Updates and Reminders",
    description: "Informational updates and reminders about your account",
    emails: [
      {
        type: UnsubscribeEmailType.APPLICATION_UPDATES,
        name: "Application Updates",
        description: "Notifications regarding new features to the application",
      },
      {
        type: UnsubscribeEmailType.REMINDERS,
        name: "Reminders",
        description: "Alerts for actions required on specific items",
      },
    ],
  },
  {
    type: UnsubscribeCategoryType.ESSENTIAL_EMAILS,
    name: "Essential Emails",
    description: "Essential informational messages",
    emails: [
      {
        type: UnsubscribeEmailType.BENEFICIARY_INVITE_ACCEPTANCE,
        name: "Beneficiary Invite Acceptance",
        description: "Informs you that a beneficiary has accepted your invite",
      },
      {
        type: UnsubscribeEmailType.BENEFICIARY_OPT_OUT,
        name: "Beneficiary Opt Out",
        description:
          "Notifies you when someone has chosen to opt out of being your beneficiary",
      },
      {
        type: UnsubscribeEmailType.BENEFICIARY_UNLOCK_REQUEST_CANCELED,
        name: "Beneficiary Unlock Request Canceled",
        description:
          "Sent when a beneficiary cancels their request to unlock your account",
      },
      {
        type: UnsubscribeEmailType.AUTOMATIC_RELOCK,
        name: "Automatic Relock",
        description:
          "Notifies you when your account has been relocked following beneficiary voting actions",
      },
      {
        type: UnsubscribeEmailType.SCHEDULED_BENEFACTOR_UNLINKING,
        name: "Scheduled Benefactor Unlinking",
        description: "Sent when you've requested to unlink from a benefactor",
      },
      {
        type: UnsubscribeEmailType.BENEFICIARY_ACCOUNT_UNLOCKED,
        name: "Beneficiary Account Unlocked",
        description: "Notifies you of a beneficiary’s account being unlocked",
      },
      {
        type: UnsubscribeEmailType.BENEFACTOR_ACCOUNT_RELOCKED,
        name: "Benefactor Account Relocked",
        description:
          "Informs you that a benefactor’s account has been relocked",
      },
      {
        type: UnsubscribeEmailType.BENEFACTOR_ACCOUNT_UNLOCKED_WITH_BACKUP_FALLBACK,
        name: "Benefactor Account May Fallback to Backups",
        description:
          "Alerts backup beneficiaries that they will gain access to a benefactor's account if there is no activity from primary beneficiaries",
      },
      {
        type: UnsubscribeEmailType.BENEFACTOR_ACCOUNT_UNLOCKED_FOR_BACKUPS,
        name: "Benefactor’s Account Now Accessible to Backups",
        description:
          "Notifies primary beneficiaries that, due to inactivity, the benefactor’s account is now available to backup beneficiaries",
      },
    ],
  },
  {
    type: UnsubscribeCategoryType.IMPORTANT_CRITICAL_EMAILS,
    name: "Important Critical Emails",
    description: "These require your attention or action",
    emails: [
      {
        type: UnsubscribeEmailType.CONNECTION_REQUEST,
        name: "Connection Request",
        description:
          "Notifies you that a benefactor has invited you to become their beneficiary",
      },
      {
        type: UnsubscribeEmailType.ACCOUNT_UNLOCK_REQUEST,
        name: "Account Unlock Request",
        description:
          "Notifies you that a beneficiary has requested to unlock your account",
      },
      {
        type: UnsubscribeEmailType.ACCOUNT_UNLOCKED,
        name: "Account Unlocked",
        description:
          "Confirms that your account is unlocked, giving beneficiaries access to your asset information",
      },
      {
        type: UnsubscribeEmailType.BENEFACTOR_ACCOUNT_UNLOCK_REQUEST,
        name: "Benefactor’s Account Unlock Request",
        description:
          "Notifies you of a request to unlock a benefactor’s account, allowing you to act if needed",
      },
      {
        type: UnsubscribeEmailType.BENEFACTOR_ACCOUNT_UNLOCKED,
        name: "Benefactor’s Account Unlocked",
        description:
          "Informs you that a benefactor’s account is unlocked, possibly allowing you to view their assets",
      },
    ],
  },
  {
    type: UnsubscribeCategoryType.PRIVATE_MESSAGES,
    name: "Personal Messages",
    emails: [
      {
        type: UnsubscribeEmailType.PRIVATE_MESSAGES,
        name: "Personal Messages",
        description:
          "Once another user's account is unlocked, they may send you a personal private message intended just for you",
      },
    ],
  },
];

const EmailPreferences = () => {
  // Initial state setup for categories and subcategories
  const [selectedCategories, setSelectedCategories] = useState<
    Record<string, { checked: boolean; subcategories: Record<string, boolean> }>
  >({});
  const [i, setI] = useState<string | null>(null);
  const [k, setK] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [showSaved, setShowSaved] = React.useState(false);

  useEffect(() => {
    const i = searchParams.get("i");

    setI(i);

    if (i) {
      const fetchEmailPreferences = async () => {
        try {
          const emailPreferences = await getEmailPreferences(i);
          setSelectedCategories((prevState) => {
            const newState: any = {};

            categories.forEach((category) => {
              newState[category.type] = {
                checked: true,
                subcategories: {},
              };
              category.emails?.forEach((email) => {
                let isSubscribed = true;
                if (emailPreferences.unsubscribedEmails.includes(email.type)) {
                  isSubscribed = false;
                }

                newState[category.type].subcategories[email.type] =
                  isSubscribed;
              });

              const noneChecked = Object.values(
                newState[category.type].subcategories
              ).every((subcategory: any) => {
                return subcategory === false;
              });

              if (noneChecked) {
                newState[category.type].checked = false;
              }
            });
            setIsLoading(false);
            return newState;
          });
        } catch (e) {
          setShowError(true);
          setIsLoading(false);
        }
      };
      fetchEmailPreferences();
    } else {
      setShowError(true);
      setIsLoading(false);
    }
  }, []);

  // Handler to toggle the "Unsubscribe All" checkbox
  const handleUnsubscribeAll = () => {
    const newState: any = {};
    categories.forEach((category) => {
      newState[category.type] = { checked: false, subcategories: {} };
      category.emails?.forEach((email) => {
        newState[category.type].subcategories[email.type] = false;
      });
    });
    setSelectedCategories(newState);
  };

  // Function to toggle an entire category and all its subcategories
  const handleCategoryToggle = (categoryType: string) => {
    setSelectedCategories((prevState) => {
      const newState = { ...prevState };
      const isCategoryChecked = newState[categoryType]?.checked || false;

      // Toggle category and all subcategories
      newState[categoryType] = {
        checked: !isCategoryChecked,
        subcategories: {},
      };

      categories
        .find((c) => c.type === categoryType)
        ?.emails?.forEach((email) => {
          newState[categoryType].subcategories[email.type] = !isCategoryChecked;
        });

      return newState;
    });
  };

  // Function to toggle individual subcategories
  const handleSubcategoryToggle = (
    categoryType: string,
    subcategoryType: string
  ) => {
    setSelectedCategories((prevState) => {
      const newState = { ...prevState };
      const category = newState[categoryType] || {
        checked: false,
        subcategories: {},
      };

      // Toggle specific subcategory
      category.subcategories[subcategoryType] =
        !category.subcategories[subcategoryType];
      newState[categoryType] = category;

      // Update category checked state based on all subcategories
      const allChecked = Object.values(category.subcategories).every(Boolean);
      const noneChecked = Object.values(category.subcategories).every(
        (v) => !v
      );
      const someChecked = Object.values(category.subcategories).some((v) => v);

      if (allChecked || someChecked) {
        category.checked = true;
      } else if (noneChecked) {
        category.checked = false;
      }

      return newState;
    });
  };

  const getUnsubscribedEmails = () => {
    const unsubscribedEmails: UnsubscribeEmailType[] = [];

    // Iterate over all categories
    categories.forEach((category) => {
      const categoryState = selectedCategories[category.type];

      if (categoryState) {
        // Iterate over all emails within the category
        category.emails?.forEach((email) => {
          // If the subcategory (email) is not checked, add it to the result
          if (!categoryState.subcategories[email.type]) {
            unsubscribedEmails.push(email.type);
          }
        });
      }
    });

    return unsubscribedEmails;
  };

  return (
    <div className="email-container">
      <div>
        <div className="unsubscribe-title">Manage Email Preferences</div>
        {isLoading ? (
          <CircularProgress className="loading" />
        ) : (
          <>
            {!showError ? (
              <div>
                <div className="unsubscribe-description">
                  Choose which emails you want to receive:
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ul>
                    {/* Category and subcategory checkboxes */}
                    {categories.map((category) => (
                      <li key={category.type}>
                        <p>
                          <Checkbox
                            className="checkbox"
                            checked={
                              selectedCategories[category.type]?.checked ||
                              false
                            }
                            onChange={() => handleCategoryToggle(category.type)}
                          />
                          <span className="category">{category.name}</span>
                          {category.description && (
                            <span className="category-description"> - </span>
                          )}
                          <span className="category-description">
                            {category.description}
                          </span>
                        </p>

                        <ul>
                          {category.emails?.map((email) => (
                            <li key={email.type}>
                              <p>
                                <Checkbox
                                  className="checkbox"
                                  size="small"
                                  checked={
                                    selectedCategories[category.type]
                                      ?.subcategories[email.type] || false
                                  }
                                  onChange={() =>
                                    handleSubcategoryToggle(
                                      category.type,
                                      email.type
                                    )
                                  }
                                />
                                <span className="subcategory">
                                  {email.name}
                                </span>
                                {email.description && (
                                  <span className="subcategory-description">
                                    {" "}
                                    -{" "}
                                  </span>
                                )}
                                <span
                                  className="subcategory-description"
                                  style={{ fontStyle: "italic" }}
                                >
                                  {email.description}
                                </span>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                    {/* "Unsubscribe from all" checkbox */}
                    <li>
                      <p>
                        <Checkbox
                          className="checkbox"
                          checked={Object.values(selectedCategories).every(
                            (category) => category.checked === false
                          )}
                          onChange={handleUnsubscribeAll}
                        />
                        <span className="category">
                          Unsubscribe from all emails
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="update-preferences-button">
                  <Button
                    onClick={async () => {
                      setShowSaved(false);
                      if (i) {
                        try {
                          const unsubscribedEmails = getUnsubscribedEmails();
                          await updateEmailPreferences(unsubscribedEmails, i);
                          setShowSaved(true);
                        } catch (error) {
                          setShowError(true);
                        }
                      } else {
                        setShowError(true);
                      }
                    }}
                    variant="contained"
                  >
                    Update Preferences
                  </Button>
                  {showSaved && (
                    <p style={{ color: "green" }}>
                      Your preferences have been saved!
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <p className="unsubscribe-error">
                  Due to an error, we were unable to retrieve your email
                  preferences. If you wish to unsubscribe to our emails, please
                  email{" "}
                  <ButtonMailto
                    label="support@farewellnotes.com"
                    mailto="mailto:support@farewellnotes.com"
                  />
                  .
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmailPreferences;
