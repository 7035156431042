import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PlatformUrlUtils from "../utils/PlatformUrlUtils";

const Download = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (typeof window !== "undefined") {
      var now = new Date().getTime();
      const os = searchParams.get("os");
      const platform = getMobileOperatingSystem();
      if (os === "iOS") {
        if (platform === "Android")
          window.location.href = PlatformUrlUtils.getAndroidUrl();
        else window.location.href = PlatformUrlUtils.getIosUrl();
      } else {
        if (platform === "iOS")
          window.location.href = PlatformUrlUtils.getIosUrl();
        else window.location.href = PlatformUrlUtils.getAndroidUrl();
      }
    }
  }, []);

  function getMobileOperatingSystem() {
    var userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return <div></div>;
};

export default Download;
