import React from "react";
import ButtonMailto from "../components/ButtonMailto";

const DeleteAccount = () => {
  return (
    <div>
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            padding: 10,
            marginTop: 10,
            color: "#4169E1",
          }}
        >
          Delete Your Data
        </h1>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            padding: 10,
            marginTop: 10,
            fontSize: 20,
            maxWidth: 1000,
          }}
        >
          <p>
          You can delete your data at any time through the Farewell Notes app by navigating to:
          <p style={{ fontWeight: "bold", color: "black", marginTop: 0 }}>
              Settings {">"} Account Security {">"} Delete You Farewell Notes
              Account
            </p>
          </p>
          <br />
          <p>
            Alternatively, you can email us at{" "}
            <ButtonMailto
              label="support@farewellnotes.com"
              mailto="mailto:support@farewellnotes.com"
            />{" "}
            to request data removal. Please ensure the email is sent from the
            address associated with the data you wish to delete. We will process
            your request and delete all associated data within one week.
          </p>
        </h1>
      </div>
    </div>
  );
};
export default DeleteAccount;
