import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonMailto from "../components/ButtonMailto";
import { getActivity } from "../services/api";
import "./index.css";
import { Button } from "@mui/material";

const Activity = () => {
  // Initial state setup for categories and subcategories
  const [selectedCategories, setSelectedCategories] = useState<
    Record<string, { checked: boolean; subcategories: Record<string, boolean> }>
  >({});
  const [u, setU] = useState<string | null>(null);
  const [k, setK] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSaved, setShowSaved] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {
    const u = searchParams.get("u");
    setU(u);
  }, []);

  return (
    <div className="container">
      <div>
        {isLoading ? (
          <CircularProgress className="loading" />
        ) : (
          <>
            {!showSaved && !showError && (
              <p style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}>
                Press the button below to confirm that you are active.
              </p>
            )}
            {!showError ? (
              <div
                className="content"
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                <div className="button-container">
                  {showSaved ? (
                    <p style={{ color: "green", textAlign: "center" }}>
                      Your activity has been updated. Thank you for confirming.
                    </p>
                  ) : (
                    <Button
                      disabled={isDisabled}
                      style={{ padding: "20px 40px", borderRadius: "50px" }}
                      onClick={async () => {
                        if (u) {
                          try {
                            setIsDisabled(true)
                            await getActivity(u);
                            setIsDisabled(false)
                            setShowSaved(true);
                          } catch (error) {
                            setIsDisabled(false)
                            setShowError(true);
                          }
                        } else {
                          setShowError(true);
                        }
                      }}
                      variant="contained"
                    >
                      I am active!
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <p className="activity-error">
                  We encountered an error and couldn't update your activity. If
                  this issue persists, please reach out to us via email:{" "}
                  <ButtonMailto
                    label="support@farewellnotes.com"
                    mailto="mailto:support@farewellnotes.com"
                  />
                  .
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Activity;
