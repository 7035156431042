import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const FAQ = () => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch("./Farewell Notes FAQ.md")
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, []);

  return (
    <div style={{paddingLeft: '10px', paddingRight: '10px', width: '95%', display: 'flex', justifyContent: 'center'}}>
      <div style={{width: '95%'}}>
      <ReactMarkdown>{markdown}</ReactMarkdown>
      </div>
    </div>
  );
};
export default FAQ;
