import { Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonMailto from "../components/ButtonMailto";
import PlatformUrlUtils from "../utils/PlatformUrlUtils";

const Invite = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSaved, setShowSaved] = React.useState(false);

  useEffect(() => {
    openFarewellNotes();
  }, []);

  function openFarewellNotes() {
    if (typeof window !== "undefined") {
      var now = new Date().getTime();
      const id = searchParams.get("id");
      const platform = getMobileOperatingSystem();
      setTimeout(function () {
        if (new Date().getTime() - now > 100) return;
        if (platform === "iOS")
          window.location.href = PlatformUrlUtils.getIosUrl();
        else window.location.href = PlatformUrlUtils.getAndroidUrl();
      }, 25);
      window.location.href = `farewellnotes://invite?id=${id}`;
    }
  }

  function getMobileOperatingSystem() {
    var userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <div className="container">
      <div>
        {isLoading ? (
          <CircularProgress className="loading" />
        ) : (
          <>
            {!showError ? (
              <div>
                <div className="content">
                  <div className="button-container">
                    {showSaved ? (
                      <p style={{ color: "green" }}>Thank you!</p>
                    ) : (
                      <Button
                        disabled={showSaved}
                        style={{ padding: "20px 40px", width: "200px", alignSelf: "center" }}
                        onClick={async () => {
                          openFarewellNotes();
                        }}
                        variant="contained"
                      >
                        View Invite
                      </Button>
                    )}
                  </div>
                </div>
                <div style={{margin: "10px", textAlign: "center"}}>
                  If you're unable to view the invitation in the Farewell Notes
                  app, please reach out to us via email:{" "}
                  <ButtonMailto
                    label="support@farewellnotes.com"
                    mailto="mailto:support@farewellnotes.com"
                  />
                </div>
              </div>
            ) : (
              <div>
                <p className="activity-error">
                  We encountered an error and couldn't open your invite. If this
                  issue persists, please reach out to us via email:{" "}
                  <ButtonMailto
                    label="support@farewellnotes.com"
                    mailto="mailto:support@farewellnotes.com"
                  />
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Invite;
