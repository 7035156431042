import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const ButtonMailto = ({ mailto, label }: { mailto: string, label: string }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                ReactGA.event({
                    category: "Link",
                    action: "Mailto Click",
                  });
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

export default ButtonMailto;