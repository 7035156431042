import { EmailPreferences, UnsubscribeEmailType } from "../models/email";

const API_URL = process.env.REACT_APP_API_URL;

const headers: { [key: string]: string } = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getEmailPreferences = async (
  i: string
): Promise<EmailPreferences> => {
  return await fetch(
    `${API_URL}/email-preferences?i=${encodeURIComponent(i)}`,
    {
      method: "GET",
      headers,
    }
  ).then((res) => {
    return res.json();
  });
};

export const updateEmailPreferences = async (
  unsubscribedEmails: UnsubscribeEmailType[],
  i: string
): Promise<void> => {
  await fetch(`${API_URL}/email-preferences`, {
    method: "PUT",
    headers,
    body: JSON.stringify({ i, unsubscribedEmails }),
  });
};

export const getActivity = async (userId: string): Promise<void> => {
  const result = await fetch(
    `${API_URL}/users/${encodeURIComponent(userId)}/activity`,
    {
      method: "GET",
      headers,
    }
  );
  if (!result.ok) {
    throw new Error("Error fetching activity");
  }
};
